<template>
  <div class="angle-input d-flex align-center">
    <number-input
      v-model="degrees"
      :disabled="disabled"
      v-bind="inputProperties.degrees"
      @change="handleComponentValueChange()"
    />
    °

    <number-input
      ref="minutesInput"
      v-model="minutes"
      :disabled="disabled"
      class="ml-2"
      v-bind="inputProperties.minutes"
      @change="handleComponentValueChange()"
    />
    ′

    <number-input
      ref="secondsInput"
      v-model="seconds"
      :disabled="disabled"
      class="ml-2"
      v-bind="inputProperties.seconds"
      @change="handleComponentValueChange()"
    />
    ″
  </div>
</template>

<script>
  import _ from 'lodash'
  import { angleComponentsToDecimal, decimalAngleToComponents } from 'geoportal/src/utils/geo'
  import NumberInput from './NumberInput'
  import { WORLD_EXTENT } from 'ol/proj/epsg3857'

  // according to EPSG:3857
  const MIN_LONGITUDE = WORLD_EXTENT[1]
  const MAX_LONGITUDE = WORLD_EXTENT[3]

  const InputType = Object.freeze({
    LONGITUDE: 'longitude',
    LATITUDE: 'latitude'
  })
  const baseRestrictions = {
    minutes: { min: 0, max: 59 },
    seconds: { min: 0, max: 59.99 }
  }
  const inputTypeRestrictions = {
    [InputType.LONGITUDE]: {
      ...baseRestrictions,
      degrees: { min: -180, max: 180 }
    },
    [InputType.LATITUDE]: {
      ...baseRestrictions,
      degrees: { min: MIN_LONGITUDE, max: MAX_LONGITUDE }
    }
  }
  const inputProperties = {
    dense: true,
    hideDetails: true
  }

  export default {
    name: 'AngleInput',
    components: { NumberInput },

    props: {
      value: {
        type: Number,
        default: 0
      },
      type: {
        type: String,
        required: true,
        validator: (value) => Object.values(InputType).includes(value)
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        degrees: 0,
        minutes: 0,
        seconds: 0
      }
    },

    computed: {
      inputProperties() {
        return _.mapValues(inputTypeRestrictions[this.type], (restrictions) => {
          return { ...restrictions, ...inputProperties }
        })
      }
    },

    watch: {
      value: {
        handler: 'handleValueChange',
        immediate: true
      }
    },

    methods: {
      handleValueChange() {
        if (_.isNil(this.value)) {
          this.degrees = 0
          this.minutes = 0
          this.seconds = 0
        } else {
          const { degrees, minutes, seconds } = decimalAngleToComponents(this.value, 2)
          this.degrees = degrees
          this.minutes = minutes
          this.seconds = seconds
        }
      },

      handleComponentValueChange() {
        const decimalValue = angleComponentsToDecimal(this.degrees, this.minutes, this.seconds)
        const { min, max } = this.inputProperties.degrees
        const clampedValue = _.clamp(decimalValue, min, max)

        if (decimalValue !== clampedValue) {
          // force the minutes and seconds inputs to reset their values
          // ugly but seems to work
          this.$nextTick(() => {
            ;[this.$refs.minutesInput, this.$refs.secondsInput].forEach((input) => {
              input.$children[0].internalValue = 0
            })
          })
        }

        this.$emit('change', clampedValue)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .angle-input {
    .number-input {
      width: 33%;
      text-align: center;
    }
  }
</style>
