import { render, staticRenderFns } from "./PlotsList.vue?vue&type=template&id=7ade9834&scoped=true&"
import script from "./PlotsList.vue?vue&type=script&lang=js&"
export * from "./PlotsList.vue?vue&type=script&lang=js&"
import style0 from "./PlotsList.vue?vue&type=style&index=0&id=7ade9834&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ade9834",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VVirtualScroll})
