import axios from 'axios'
import { pointToLineDistance, nearestPointOnLine, lineSlice } from '@turf/turf'
import GeoJSON from 'ol/format/GeoJSON'
import GeometryType from 'ol/geom/GeometryType'
import { getArea } from 'ol/sphere'
import VectorSource from 'ol/source/Vector'
import { Circle, Fill, Stroke, Style, Text } from 'ol/style'
import { showErrorMessage } from 'geoportal/src/utils/common'
import { getFullLayerID } from 'geoportal/src/utils/geoServer'
import { checkFeatureSelfIntersection, EPSG_3857, EPSG_4326, fromLonLat, toLonLat } from 'geoportal/src/utils/geo'
import Portal from 'geoportal/src/portal'
import settings from '@/settings'
import { LAYER_NAMESPACE, plotPropertiesPrecision } from '@/configurations/aquarius'
import { formatNumber } from '@/utils/common'
import { CAN_BE_BOUND_TO_COASTLINE_DISTANCE, formatPlotArea, MAX_PLOT_AREA, MIN_PLOT_AREA } from '@/utils/plots'

const BOUND_TO_COASTLINE_DISTANCE = 0.15 * Math.sqrt(2) // meters

export const PLOT_VERTEX_STATE_PROPERTY = 'pointState'
export const PlotVertexState = Object.freeze({
  DEFAULT: 'default',
  CAN_BE_BOUND_TO_COASTLINE: 'canBeBoundToCoastline',
  BOUND_TO_COASTLINE: 'boundToCoastline'
})
export const PLOT_EDGE_STATE_PROPERTY = 'edgeState'
export const PlotEdgeState = Object.freeze({
  DEFAULT: 'default',
  CAN_BE_BOUND_TO_COASTLINE: 'canBeBoundToCoastline',
  BOUND_TO_COASTLINE: 'boundToCoastline'
})

export const DEFAULT_PLOT_ELEMENT_COLOR = '#1976d2'
export const CAN_BE_BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR = '#8e24aa'
export const BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR = '#2e7d32'

export function checkPlotGeometry(polygon) {
  const polygonFeature = new GeoJSON().writeFeatureObject(polygon)

  const isSelfIntersecting = !checkFeatureSelfIntersection(polygonFeature)
  if (isSelfIntersecting) {
    showErrorMessage('Геометрия участка не должна самопересекаться')
    return false
  }

  const olGeometry = polygon.getGeometry()
  const areaHectares = formatPlotArea(getArea(olGeometry))
  const hasIncorrectSize = areaHectares < MIN_PLOT_AREA || areaHectares > MAX_PLOT_AREA
  if (hasIncorrectSize) {
    showErrorMessage(`Площадь создаваемого участка должна быть от ${MIN_PLOT_AREA} до ${MAX_PLOT_AREA} га`)
    return false
  }

  return true
}

// fetch features in EPSG:4326 because the size of request would be much lesser
const geoJsonReader = new GeoJSON({
  dataProjection: EPSG_4326,
  featureProjection: EPSG_3857
})

async function fetchGeoserverLayersFeatures(geoserverLayerIds) {
  const responses = await Promise.all(
    geoserverLayerIds.map((geoserverLayerId) =>
      axios.get(`${settings.urls.geoServer}/${LAYER_NAMESPACE}/wfs`, {
        params: {
          service: 'WFS',
          version: '2.0.0',
          request: 'GetFeature',
          typeNames: getFullLayerID(LAYER_NAMESPACE, geoserverLayerId),
          outputFormat: 'application/json',
          srsName: EPSG_4326
        }
      })
    )
  )
  return responses.map((response) => response.data.features).flat()
}

export function createCoastlinesIndex(geoserverLayerIds) {
  const featuresIndex = new VectorSource({
    async loader() {
      const features = await fetchGeoserverLayersFeatures(geoserverLayerIds)
      const olFeatures = features
        .map((feature) => {
          // convert MultiLineString to LineStrings
          if (feature.geometry.type === GeometryType.MULTI_LINE_STRING) {
            return feature.geometry.coordinates.map((coordinates) => ({
              type: GeometryType.LINE_STRING,
              coordinates
            }))
          } else {
            return feature.geometry
          }
        })
        .flat()
        .map((geometry) => {
          const feature = {
            type: 'Feature',
            geometry
          }
          const olFeature = geoJsonReader.readFeature(feature)
          // required for drawing
          olFeature.set('geometry4326', geometry)
          return olFeature
        })
      this.addFeatures(olFeatures)
    }
  })
  featuresIndex.loadFeatures()
  return featuresIndex
}

export function createIslandsIndex(geoserverLayerIds) {
  const featuresIndex = new VectorSource({
    async loader() {
      const features = await fetchGeoserverLayersFeatures(geoserverLayerIds)
      const olFeatures = features.map((feature) => {
        const olFeature = geoJsonReader.readFeature(feature)
        olFeature.setId(feature.id)
        return olFeature
      })
      this.addFeatures(olFeatures)
    }
  })
  featuresIndex.loadFeatures()
  return featuresIndex
}

// TODO: it would be better to set the BOUND_TO_COASTLINE state manually on binding
//  but then it's not possible to distinguish
//  whether the modification of vertex were made
//  by interaction or by the binding action
export function getPlotVertexState(coastlinesIndex, vertexFeature) {
  const coordinates = vertexFeature.getGeometry().getCoordinates()
  const olCoastlineFeature = coastlinesIndex.getClosestFeatureToCoordinate(coordinates)
  const coastlineGeometry = olCoastlineFeature.get('geometry4326')
  const pointGeometry = {
    type: GeometryType.POINT,
    coordinates: toLonLat(coordinates)
  }

  const distance = pointToLineDistance(pointGeometry, coastlineGeometry) * 1000

  if (distance <= BOUND_TO_COASTLINE_DISTANCE) {
    return PlotVertexState.BOUND_TO_COASTLINE
  } else if (distance <= CAN_BE_BOUND_TO_COASTLINE_DISTANCE) {
    return PlotVertexState.CAN_BE_BOUND_TO_COASTLINE
  } else {
    return PlotVertexState.DEFAULT
  }
}

export function getPlotEdgeState(coastlinesIndex, edgeFeature, firstVertexFeature, secondVertexFeature) {
  if (
    firstVertexFeature.get(PLOT_VERTEX_STATE_PROPERTY) === PlotVertexState.BOUND_TO_COASTLINE &&
    secondVertexFeature.get(PLOT_VERTEX_STATE_PROPERTY) === PlotVertexState.BOUND_TO_COASTLINE
  ) {
    const edgeCoordinates = edgeFeature.getGeometry().getCoordinates()
    const firstCoastlineFeature = coastlinesIndex.getClosestFeatureToCoordinate(edgeCoordinates[0])
    const secondCoastlineFeature = coastlinesIndex.getClosestFeatureToCoordinate(edgeCoordinates[1])

    return firstCoastlineFeature === secondCoastlineFeature
      ? PlotEdgeState.CAN_BE_BOUND_TO_COASTLINE
      : PlotEdgeState.DEFAULT
  } else {
    return PlotEdgeState.DEFAULT
  }
}

export function bindPlotVertexToCoastline(coastlinesIndex, point) {
  const olCoastlineFeature = coastlinesIndex.getClosestFeatureToCoordinate(point)
  const coastlineGeometry = olCoastlineFeature.get('geometry4326')
  const pointGeometry = {
    type: GeometryType.POINT,
    coordinates: toLonLat(point)
  }

  const nearestPoint = nearestPointOnLine(coastlineGeometry, pointGeometry)
  return fromLonLat(nearestPoint.geometry.coordinates)
}

export function bindPlotEdgeToCoastline(coastlinesIndex, line) {
  const olCoastlineFeature = coastlinesIndex.getClosestFeatureToCoordinate(line[0])
  const coastlineFeature = {
    type: 'Feature',
    geometry: olCoastlineFeature.get('geometry4326')
  }

  line = line.map(toLonLat)

  const edgeFeature = lineSlice(line[0], line[1], coastlineFeature)
  return edgeFeature.geometry.coordinates.map(fromLonLat)
}

const vertexDefaultBackgroundStyle = new Circle({
  fill: new Fill({
    color: DEFAULT_PLOT_ELEMENT_COLOR
  }),
  radius: 8
})
const vertexCanBeBoundToCoastlineBackgroundStyle = new Circle({
  fill: new Fill({
    color: CAN_BE_BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR
  }),
  radius: 8
})
const vertexBoundToCoastlineBackgroundStyle = new Circle({
  fill: new Fill({
    color: BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR
  }),
  radius: 8
})

export function getVertexStyleFunction(vertexNumber) {
  const textStyle = new Text({
    font: '12px "Roboto", sans-serif',
    fill: new Fill({
      color: '#ffffff'
    }),
    text: String(vertexNumber + 1)
  })
  const defaultStyle = [
    new Style({
      image: vertexDefaultBackgroundStyle,
      text: textStyle
    })
  ]
  const canBeBoundToCoastlineStyle = [
    new Style({
      image: vertexCanBeBoundToCoastlineBackgroundStyle,
      text: textStyle
    })
  ]
  const boundToCoastlineStyle = [
    new Style({
      image: vertexBoundToCoastlineBackgroundStyle,
      text: textStyle
    })
  ]
  return (feature) => {
    switch (feature.get(PLOT_VERTEX_STATE_PROPERTY)) {
      case PlotVertexState.CAN_BE_BOUND_TO_COASTLINE:
        return canBeBoundToCoastlineStyle
      case PlotVertexState.BOUND_TO_COASTLINE:
        return boundToCoastlineStyle
      default:
        return defaultStyle
    }
  }
}

const edgeDefaultStyle = new Style({
  stroke: new Stroke({
    color: DEFAULT_PLOT_ELEMENT_COLOR,
    width: 3
  })
})
const edgeCanBeBoundToCoastlineStyle = new Style({
  stroke: new Stroke({
    color: CAN_BE_BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR,
    width: 3
  })
})
const edgeBoundToCoastlineStyle = new Style({
  stroke: new Stroke({
    color: BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR,
    width: 3
  })
})

export function edgeStyleFunction(feature) {
  let style

  switch (feature.get(PLOT_EDGE_STATE_PROPERTY)) {
    case PlotEdgeState.CAN_BE_BOUND_TO_COASTLINE:
      style = edgeCanBeBoundToCoastlineStyle
      break
    case PlotEdgeState.BOUND_TO_COASTLINE:
      style = edgeBoundToCoastlineStyle
      break
    default:
      style = edgeDefaultStyle
  }

  return style
}

export function getPolygonStyleFunction() {
  const polygonStyle = new Style({
    fill: new Fill({
      color: 'rgba(255,255,255,0.4)'
    }),
    stroke: new Stroke({
      color: BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR,
      width: 3
    })
  })
  const labelStyle = new Style({
    text: new Text({
      font: '500 16px "Roboto", sans-serif',
      stroke: new Stroke({
        color: '#ffffff',
        width: 2
      })
    })
  })

  let lastFeatureRevision = null
  const polygonStyles = [polygonStyle, labelStyle]
  return (feature) => {
    const featureRevision = feature.getRevision()
    if (lastFeatureRevision !== featureRevision) {
      lastFeatureRevision = featureRevision
      const area = formatPlotArea(getArea(feature.getGeometry()))
      Portal.store.set('map/plotArea', area)
      labelStyle.getText().setText(`${formatNumber(area, plotPropertiesPrecision.area)} га`)
    }

    return polygonStyles
  }
}

export const vertexSelectStyle = new Style({
  image: new Circle({
    stroke: new Stroke({
      color: '#03a9f4',
      width: 3
    }),
    radius: 9
  })
})
export const edgeSelectStyle = new Style({
  stroke: new Stroke({
    color: '#03a9f4',
    width: 9
  })
})
