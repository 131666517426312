<template>
  <v-sheet
    class="plot-card px-5 py-3"
    :class="{ 'plot-card_plots-list': isPlotsList }"
    @click="isPlotsList ? selectPlot() : undefined"
  >
    <v-btn v-if="!isPlotsList" class="mb-3" icon small @click.stop="goBack()">
      <v-icon>{{ $vuetify.icons.values.goBack }}</v-icon>
    </v-btn>

    <div class="d-flex justify-space-between align-center">
      <v-breadcrumbs class="pa-0 font-weight-bold" :items="breadcrumbs" />

      <v-btn class="plot-card__zoom-btn" small icon @click.stop="zoomToPlot()">
        <v-icon>mdi-image-filter-center-focus</v-icon>
      </v-btn>
    </div>

    <div class="d-flex mt-3">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-if="!isNotSavedDraft" class="d-flex align-center">
          <v-btn
            v-if="$auth.authenticated"
            :disabled="!isEmailConfirmed || pendingTasks.setPlotFavorite"
            :loading="pendingTasks.setPlotFavorite"
            small
            icon
            @click.stop="handleSetPlotFavorite()"
          >
            <v-icon v-if="plot.id in favoritePlots">mdi-star</v-icon>
            <v-icon v-else>mdi-star-outline</v-icon>
          </v-btn>

          <span class="plot-card__name ml-1 guideSecondary1--text">{{ plot.name }}</span>
        </div>

        <v-spacer />

        <div class="plot-card__area">{{ area }} га</div>
      </div>

      <v-sheet
        class="plot-card__status w-50 ml-2 px-3 py-2 d-flex justify-center align-center text-center font-weight-bold"
        :style="{ color: statusGroup.color, backgroundColor: statusGroup.backgroundColor }"
        rounded
      >
        {{ statusGroup.text }}
      </v-sheet>
    </div>
  </v-sheet>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { PlotStatus } from '@/enums/aquarius'
  import { plotPropertiesPrecision, plotStatusesToGroups } from '@/configurations/aquarius'
  import { AsidePanelState } from '@/views/MapView/mapView'
  import { formatNumber } from '@/utils/common'

  export default {
    name: 'PlotCard',
    mixins: [PendingTasksManagerMixin],

    props: {
      plot: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...get('map', {
        asidePanelState: 'asidePanelState',
        favoritePlots: 'favoritePlots',
        drawnPlot: 'drawnPlot'
      }),

      isEmailConfirmed() {
        return !this.$auth.user.pending_email_confirmation
      },

      isPlotsList() {
        return this.asidePanelState === AsidePanelState.PLOTS_LIST
      },

      isNotSavedDraft() {
        return this.plot === this.drawnPlot
      },

      breadcrumbs() {
        return [this.plot.water_body, this.plot.federal_subject].filter(Boolean).map((text) => ({ text }))
      },

      area() {
        return formatNumber(this.plot.area_ha, this.isPlotsList ? 0 : plotPropertiesPrecision.area)
      },

      statusGroup() {
        return this.plot.status in plotStatusesToGroups
          ? plotStatusesToGroups[this.plot.status]
          : {
              ...plotStatusesToGroups[PlotStatus.DRAFT],
              text: this.$t(`aquarius.plotStatus.${this.plot.status}`)
            }
      }
    },

    methods: {
      ...call('map', {
        setAsidePanelState: 'setAsidePanelState',
        setPlotFavorite: 'setPlotFavorite'
      }),

      selectPlot() {
        this.setAsidePanelState([AsidePanelState.PLOT, { plotId: this.plot.id }])
      },

      async goBack() {
        await this.setAsidePanelState([AsidePanelState.PLOTS_LIST])
      },

      zoomToPlot() {
        this.$bus.emit('map/zoomToPlot', this.plot)
      },

      @handlePendingTask('setPlotFavorite')
      async handleSetPlotFavorite() {
        await this.setPlotFavorite({
          plotId: this.plot.id,
          isFavorite: !(this.plot.id in this.favoritePlots)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .plot-card {
    .v-breadcrumbs {
      &::v-deep {
        .v-breadcrumbs__divider {
          color: map-deep-get($material-light, 'text', 'primary');
        }
      }
    }

    .plot-card__name {
      font-size: 14px;
      line-height: 16.1px;
    }

    .plot-card__area {
      font-size: 24px;
      line-height: 28px;
    }

    &.plot-card_plots-list {
      cursor: pointer;

      &:not(:hover) {
        .plot-card__zoom-btn {
          opacity: 0;
        }
      }

      .plot-card__area {
        font-size: 40px;
        line-height: 46px;
      }
    }
  }
</style>
