<template>
  <v-sheet v-if="value" class="plot-coordinates">
    <div v-for="(n, i) of PLOT_POINTS_NUMBER" :key="i" class="point-coordinate" :class="{ 'mt-8': i }">
      <div class="point-coordinate__number">{{ n }}</div>

      <div class="text-center">Ш</div>
      <angle-input
        :value="value[i][1]"
        type="latitude"
        :disabled="disabled"
        @change="handleCoordinatesChange(i, 1, $event)"
      />

      <div class="text-center">Д</div>
      <angle-input
        :value="value[i][0]"
        type="longitude"
        :disabled="disabled"
        @change="handleCoordinatesChange(i, 0, $event)"
      />
    </div>
  </v-sheet>
</template>

<script>
  import { PLOT_POINTS_NUMBER } from '@/utils/plots'
  import AngleInput from '@/components/AngleInput'

  export default {
    name: 'PlotCoordinates',
    components: { AngleInput },

    props: {
      value: {
        type: Array,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        PLOT_POINTS_NUMBER
      }
    },

    methods: {
      handleCoordinatesChange(coordinateIndex, typeIndex, coordinateValue) {
        const value = [...this.value]
        value[coordinateIndex] = [...value[coordinateIndex]]
        value[coordinateIndex][typeIndex] = coordinateValue
        this.$emit('input', value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .plot-coordinates {
    .point-coordinate {
      display: grid;
      grid-template-columns: min-content min-content 1fr;
      align-items: end;
      gap: 12px 24px;

      .point-coordinate__number {
        grid-row: 1 / 3;
        align-self: center;
        width: 24px;
        height: 24px;
        padding: 5px;
        background-color: #1276cf;
        line-height: 16px;
        text-align: center;
        font-weight: bold;
        border-radius: 50%;
        color: white;
      }
    }
  }
</style>
