<template>
  <div ref="mapContainer" class="map-view">
    <region-selector />

    <plot-drawing-manual v-if="isPlotDrawingActive" />
    <v-btn v-else class="create-plot-btn" absolute rounded color="primary" @click="startPlotDrawing()">
      <v-icon left>mdi-plus</v-icon>
      Создать участок
    </v-btn>

    <map-legend />

    <plot-drawing-warning-dialog v-model="isPlotDrawingWarningDialogActive" />

    <v-btn v-show="overlay" ref="bindPlotToCoastlineButton" small color="primary" @click="bindPlotToCoastline()">
      Притянуть к берегу
    </v-btn>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { get, call, sync } from 'vuex-pathify'
  import { polygon as createPolygonFeature } from '@turf/helpers'
  import { distance as getDistance, difference } from '@turf/turf'
  import Map from 'ol/Map'
  import View from 'ol/View'
  import VectorLayer from 'ol/layer/Vector'
  import VectorSource from 'ol/source/Vector'
  import GraticuleLayer from 'ol/layer/Graticule'
  import { Draw, Modify, Select } from 'ol/interaction'
  import Zoom from 'ol/control/Zoom'
  import MousePosition from 'ol/control/MousePosition'
  import ScaleLine from 'ol/control/ScaleLine'
  import OverviewMap from 'ol/control/OverviewMap'
  import Attribution from 'ol/control/Attribution'
  import GeometryType from 'ol/geom/GeometryType'
  import Point from 'ol/geom/Point'
  import LineString from 'ol/geom/LineString'
  import Feature from 'ol/Feature'
  import Overlay from 'ol/Overlay'
  import { getCenter } from 'ol/extent'
  import Collection from 'ol/Collection'
  import { never, click, noModifierKeys } from 'ol/events/condition'
  import WMSServerType from 'ol/source/WMSServerType'
  import TileLayer from 'ol/layer/Tile'
  import TileWMSSource from 'ol/source/TileWMS'
  import GeoJSON from 'ol/format/GeoJSON'
  import { EPSG_4326, transformCoordinateToEpsg4326, fromLonLat, toLonLat, EPSG_3857 } from 'geoportal/src/utils/geo'
  import { renderLayerFeatures, zoomToCoordinates, zoomToFeature, zoomToLayer } from 'geoportal/src/utils/ol'
  import { getFullLayerID } from 'geoportal/src/utils/geoServer'
  import { Deferred } from 'geoportal/src/utils/Deferred'
  import { Basin, LayerType } from '@/enums/aquarius'
  import settings from '@/settings'
  import { LAYER_NAMESPACE } from '@/configurations/aquarius'
  import { basinExtents } from '@/configurations/regions'
  import { checkIfTouchDevice } from '@/utils/common'
  import { PLOT_POINTS_NUMBER } from '@/utils/plots'
  import { createDefaultBaseLayer } from '@/utils/map'
  import {
    getPlotVertexState,
    PlotVertexState,
    PlotEdgeState,
    bindPlotVertexToCoastline,
    bindPlotEdgeToCoastline,
    PLOT_EDGE_STATE_PROPERTY,
    PLOT_VERTEX_STATE_PROPERTY,
    getPlotEdgeState,
    getVertexStyleFunction,
    edgeStyleFunction,
    getPolygonStyleFunction,
    vertexSelectStyle,
    edgeSelectStyle,
    createCoastlinesIndex,
    createIslandsIndex
  } from './plotDrawing'
  import MapLegend from './MapLegend'
  import PlotDrawingWarningDialog from '@/views/MapView/MapContainer/PlotDrawingWarningDialog'
  import RegionSelector from '@/views/MapView/MapContainer/RegionSelector'
  import PlotDrawingManual from '@/views/MapView/MapContainer/PlotDrawingManual'
  import { AsidePanelState, layerConfigurations } from '@/views/MapView/mapView'

  const DRAWING_LAYER_ID = 'drawingLayer'

  const geoJsonReader = new GeoJSON()

  export default {
    name: 'MapContainer',
    components: {
      PlotDrawingManual,
      RegionSelector,
      PlotDrawingWarningDialog,
      MapLegend
    },

    data() {
      return {
        isPlotDrawingWarningDialogActive: false,
        isPlotDrawingWarningShown: false,
        overlay: null
      }
    },

    computed: {
      ...get('map', {
        selectedPlot: 'selectedPlot',
        isPlotDrawingActive: 'isPlotDrawingActive'
      }),
      ...sync('map', {
        basin: 'basin',
        visibleViewExtent: 'visibleViewExtent',
        isPlotFeatureDrawn: 'isPlotFeatureDrawn',
        plotCoordinates: 'plotCoordinates'
      })
    },

    watch: {
      isPlotDrawingActive: 'handleIsPlotDrawingActiveChange',

      '$route.query.basin'(basin) {
        basin && this.zoomToBasin()
      },

      selectedPlot() {
        renderLayerFeatures(this.map, layerConfigurations.buffer_zones.get('id'))
      }
    },

    created() {
      // static data
      this.map = null
      this.mapCreatedDeferred = new Deferred()
      this.coastlinesIndex = null
      this.islandsIndex = null
      this.plotDrawing = {
        source: null,
        layer: null,
        drawInteraction: null,
        modifyInteraction: null,
        polygonFeature: null,
        vertexFeatures: null,
        edgeFeatures: null
      }

      this.$bus.listen(this, 'map/zoomToCoordinates', (coordinates, params) =>
        zoomToCoordinates(this.map, coordinates, params)
      )
      this.$bus.listen(this, 'map/zoomToPlot', this.zoomToPlot)
      this.$bus.listen(this, 'map/zoomToDrawnPlotFeature', () => zoomToLayer(this.map, DRAWING_LAYER_ID))

      this.$bus.listen(this, 'map/refreshPlotLayers', (refreshBufferZones = true) => {
        const bufferZonesLayer = layerConfigurations.buffer_zones
        if (!bufferZonesLayer.getVisible()) {
          bufferZonesLayer.setVisible(true)
        }

        layerConfigurations.plots.getSource().refresh()
        refreshBufferZones && layerConfigurations.buffer_zones.getSource().refresh()
      })

      this.$bus.listen(this, 'map/updatePlotCoordinates', (coordinates) => {
        coordinates.forEach((pointCoordinates, i) => {
          const vertexFeature = this.plotDrawing.vertexFeatures[i]
          pointCoordinates = fromLonLat(pointCoordinates)
          vertexFeature.getGeometry().setCoordinates(pointCoordinates)
        })
      })

      this.$bus.listen(this, 'map/getPlotGeometry', (resolve, reject) => {
        try {
          resolve(this.plotDrawing.polygonFeature.getGeometry().getCoordinates())
        } catch (e) {
          reject(e)
        }
      })

      this.setLayers()
    },

    mounted() {
      this.setupMap()
    },

    methods: {
      ...call('map', {
        setAsidePanelState: 'setAsidePanelState'
      }),

      setupMap() {
        this.map = new Map({
          target: this.$refs.mapContainer,
          view: new View({
            center: [0, 0],
            zoom: 2,
            enableRotation: false
          }),
          layers: this.getPreparedLayers()
        })
        this.mapCreatedDeferred.resolve(this.map)

        this.setupControls()
        this.setupMapListeners()
        this.setupPlotDrawing()

        this.zoomToBasin(true)
      },

      getPreparedLayers() {
        // TODO: use custom label formatters?
        const graticuleLayer = new GraticuleLayer({
          id: 'graticule',
          showLabels: true,
          zIndex: Infinity
        })

        return [createDefaultBaseLayer(), layerConfigurations.plots, layerConfigurations.buffer_zones, graticuleLayer]
      },

      async setLayers() {
        let layers = await this.$api.backend.layers.get_layers()

        layers = layers.filter((layer) => layer.active_version)

        const olLayers = _(layers)
          .filter((layer) => layer.visible)
          // 1. additional, required
          // 2. reverse order by field "order"
          .orderBy([(layer) => Number(layer.type === LayerType.ADDITIONAL), 'order'], ['desc', 'desc'])
          .map(
            (layer, i) =>
              new TileLayer({
                id: layer.active_version.geoserver_layer_name,
                zIndex: 100 + i,
                source: new TileWMSSource({
                  url: `${settings.urls.geoServer}/${LAYER_NAMESPACE}/wms`,
                  params: {
                    LAYERS: getFullLayerID(LAYER_NAMESPACE, layer.active_version.geoserver_layer_name),
                    TILED: true,
                    STYLES: layer.type === LayerType.ADDITIONAL ? undefined : layer.type
                  },
                  serverType: WMSServerType.GEOSERVER
                })
              })
          )
          .value()

        const groupedLayers = _.groupBy(layers, 'type')

        const coastlinesLayers = groupedLayers[LayerType.COASTLINES] ?? []
        this.coastlinesIndex = createCoastlinesIndex(_.map(coastlinesLayers, 'active_version.geoserver_layer_name'))

        const islandsLayers = groupedLayers[LayerType.ISLANDS] ?? []
        this.islandsIndex = createIslandsIndex(_.map(islandsLayers, 'active_version.geoserver_layer_name'))

        await this.mapCreatedDeferred.promise

        olLayers.forEach((olLayer) => this.map.addLayer(olLayer))
      },

      setupControls() {
        const isTouchDevice = checkIfTouchDevice()

        const controls = this.map.getControls()
        controls.clear()

        const zoomInLabel = document.createElement('span')
        zoomInLabel.classList.add('v-icon', 'mdi', 'mdi-plus')
        const zoomOutLabel = document.createElement('span')
        zoomOutLabel.classList.add('v-icon', 'mdi', 'mdi-minus')
        controls.push(
          new Zoom({
            zoomInLabel,
            zoomInTipLabel: '',
            zoomOutLabel,
            zoomOutTipLabel: ''
          })
        )

        !isTouchDevice &&
          controls.push(
            new MousePosition({
              coordinateFormat: (coordinate) =>
                transformCoordinateToEpsg4326(coordinate)
                  .map((value) => `${value.toFixed(6)}°`)
                  .reverse()
                  .join(' '),
              projection: EPSG_4326,
              undefinedHTML: ''
            })
          )

        controls.push(new ScaleLine())

        !isTouchDevice &&
          controls.push(
            new OverviewMap({
              className: 'ol-overviewmap elevation-3',
              collapsible: false,
              layers: [createDefaultBaseLayer()]
            })
          )

        controls.push(new Attribution())
      },

      setupMapListeners() {
        this.map.on('singleclick', this.handleMapClick)
        this.map.getView().on('change:center', _.throttle(this.handleViewChange, 1000))
      },

      async handleMapClick(event) {
        if (this.isPlotDrawingActive) {
          return
        }

        const features = await layerConfigurations.plots.getFeatures(event.pixel)
        const feature = features[0]
        if (!feature) {
          this.setAsidePanelState([AsidePanelState.PLOTS_LIST])
          return
        }

        const plotId = feature.get('id')
        // not saved draft
        if (!plotId) {
          return
        }

        this.setAsidePanelState([AsidePanelState.PLOT, { plotId }])
      },

      startPlotDrawing() {
        if (this.isPlotDrawingWarningShown) {
          this.setAsidePanelState([AsidePanelState.CREATE_PLOT])
        } else {
          this.isPlotDrawingWarningShown = true
          this.isPlotDrawingWarningDialogActive = true
        }
      },

      setupPlotDrawing() {
        const drawingSource = new VectorSource()
        const drawingLayer = new VectorLayer({
          id: DRAWING_LAYER_ID,
          zIndex: 10_000,
          source: drawingSource
        })
        this.map.addLayer(drawingLayer)

        const vertexFeatures = _.range(PLOT_POINTS_NUMBER).map((i) => {
          const geometry = new Point([0, 0])
          const feature = new Feature({ geometry })
          feature.set(PLOT_VERTEX_STATE_PROPERTY, null)
          feature.set('vertexNumber', i)

          const style = getVertexStyleFunction(i)
          feature.setStyle(style)
          feature.set('style', style)

          feature.getGeometry().on('change', () => {
            // eslint-disable-next-line no-use-before-define
            this.hideOverlay()

            // eslint-disable-next-line no-use-before-define
            updateEdgeCoordinatesByVertex(i)
            // eslint-disable-next-line no-use-before-define
            feature.get(PLOT_VERTEX_STATE_PROPERTY) === null ? updateVertexState(i) : updateVertexStateThrottled(i)
            // eslint-disable-next-line no-use-before-define
            updatePlotCoordinatesInStore()
          })

          return feature
        })

        const clipIslandsFromPlot = _.debounce(() => {
          const { polygonFeature } = this.plotDrawing

          let plotFeature = geoJsonReader.writeFeatureObject(polygonFeature)

          const verticesCoordinates = this.plotDrawing.vertexFeatures.map((olFeature) =>
            olFeature.getGeometry().getCoordinates()
          )

          let isIncorrectGeometry = false
          let hasIslandsIntersections = false
          this.islandsIndex.forEachFeatureIntersectingExtent(polygonFeature.getGeometry().getExtent(), (olFeature) => {
            hasIslandsIntersections = true

            // check if vertex lies onto island
            for (const vertexCoordinates of verticesCoordinates) {
              if (olFeature.getGeometry().intersectsCoordinate(vertexCoordinates)) {
                console.debug('NOCLIP: plot point is placed onto an island')
                isIncorrectGeometry = true
                return isIncorrectGeometry
              }
            }

            // find the difference between a plot and the intersecting island
            const islandFeature = geoJsonReader.writeFeatureObject(olFeature)
            plotFeature = difference(plotFeature, islandFeature)

            // plot shouldn't be divided into the separate parts
            isIncorrectGeometry = plotFeature.geometry.type !== GeometryType.POLYGON
            isIncorrectGeometry &&
              console.debug('NOCLIP: plot is divided into the separate parts', plotFeature.geometry.type)

            return isIncorrectGeometry
          })

          !hasIslandsIntersections && console.debug('NOCLIP: no islands intersections')

          if (!hasIslandsIntersections || isIncorrectGeometry) {
            return
          }

          polygonFeature.getGeometry().setCoordinates(plotFeature.geometry.coordinates)

          // update edges
          const exteriorRing = plotFeature.geometry.coordinates[0]
          const edgesCoordinates = []
          let buffer = []
          let lastVertexIndex
          exteriorRing.forEach((coordinates) => {
            buffer.push(coordinates)

            const vertexIndex = verticesCoordinates.findIndex((coordinates_) => _.isEqual(coordinates_, coordinates))
            if (vertexIndex !== -1) {
              if (_.isNil(lastVertexIndex)) {
                lastVertexIndex = vertexIndex
                return
              }

              edgesCoordinates.push([[lastVertexIndex, vertexIndex], buffer])
              buffer = [coordinates]
              lastVertexIndex = vertexIndex
            }
          })

          const isStraightOrder = edgesCoordinates.every(
            ([[firstVertexNumber, secondVertexNumber]]) =>
              firstVertexNumber === PLOT_POINTS_NUMBER - 1 || firstVertexNumber < secondVertexNumber
          )
          if (isStraightOrder) {
            edgesCoordinates.forEach(([, coordinates]) => coordinates.reverse())
          } else {
            edgesCoordinates.forEach(([verticesNumbers]) => verticesNumbers.reverse())
          }

          edgesCoordinates.forEach(([[firstVertexNumber], coordinates]) => {
            const edgeFeature = this.plotDrawing.edgeFeatures[firstVertexNumber]
            const currentCoordinates = edgeFeature.getGeometry().getCoordinates()
            if (_.isEqual(currentCoordinates, coordinates)) {
              return
            }
            edgeFeature.getGeometry().setCoordinates(coordinates)
            edgeFeature.set(PLOT_EDGE_STATE_PROPERTY, PlotEdgeState.BOUND_TO_COASTLINE)
          })
        }, 500)

        const updatePolygonCoordinates = () => {
          let coordinates = this.plotDrawing.edgeFeatures
            .map((feature) => {
              const edgeCoordinates = feature.getGeometry().getCoordinates()
              return edgeCoordinates.slice(0, edgeCoordinates.length - 1)
            })
            .reverse()
            .flat()
          coordinates = [[...coordinates, coordinates[0]]]

          this.plotDrawing.polygonFeature.getGeometry().setCoordinates(coordinates)

          clipIslandsFromPlot()
        }

        const edgeFeatures = _.range(PLOT_POINTS_NUMBER).map((i) => {
          const geometry = new LineString([
            [0, 0],
            [0, 0]
          ])
          const feature = new Feature({ geometry })
          feature.set('edgeNumber', i)
          feature.set(PLOT_EDGE_STATE_PROPERTY, null)
          feature.setStyle(edgeStyleFunction)
          feature.set('style', edgeStyleFunction)

          feature.getGeometry().on('change', () => {
            updatePolygonCoordinates()
          })

          return feature
        })

        const updateEdgeState = (edgeNumber) => {
          const edgeFeature = edgeFeatures[edgeNumber]
          const firstVertexFeature = vertexFeatures[edgeNumber]
          const secondVertexFeature = vertexFeatures[(edgeNumber + 1) % PLOT_POINTS_NUMBER]

          edgeFeature.set(
            PLOT_EDGE_STATE_PROPERTY,
            getPlotEdgeState(this.coastlinesIndex, edgeFeature, firstVertexFeature, secondVertexFeature)
          )
        }

        const updateEdgeCoordinatesByVertex = (vertexNumber) => {
          const vertexFeature = vertexFeatures[vertexNumber]
          const vertexCoordinates = vertexFeature.getGeometry().getCoordinates()

          const firstEdgeFeature = edgeFeatures[vertexNumber]
          const secondEdgeFeature = edgeFeatures[vertexNumber === 0 ? PLOT_POINTS_NUMBER - 1 : vertexNumber - 1]

          const firstEdgeFeatureCoordinates = firstEdgeFeature.getGeometry().getCoordinates()
          const secondEdgeFeatureCoordinates = secondEdgeFeature.getGeometry().getCoordinates()

          firstEdgeFeature.getGeometry().setCoordinates([firstEdgeFeatureCoordinates[0], vertexCoordinates])
          secondEdgeFeature.getGeometry().setCoordinates([vertexCoordinates, _.last(secondEdgeFeatureCoordinates)])
        }

        const updateVertexState = (vertexNumber) => {
          const vertexFeature = vertexFeatures[vertexNumber]
          vertexFeature.set(PLOT_VERTEX_STATE_PROPERTY, getPlotVertexState(this.coastlinesIndex, vertexFeature))

          updateEdgeState(vertexNumber)
          updateEdgeState(vertexNumber === 0 ? PLOT_POINTS_NUMBER - 1 : vertexNumber - 1)
        }
        const updateVertexStateThrottled = _.throttle(updateVertexState, 200)

        const updatePlotCoordinatesInStore = _.throttle(() => {
          this.plotCoordinates = vertexFeatures.map((feature) => toLonLat(feature.getGeometry().getCoordinates()))
        }, 200)

        drawingSource.on('change', () => {
          const features = drawingSource.getFeatures()
          const isPolygon = !this.isPlotFeatureDrawn && features.length === 1

          // polygon was drawn
          if (isPolygon) {
            this.plotDrawing.polygonFeature = features[0]

            // eslint-disable-next-line no-use-before-define
            drawInteraction.setActive(false)
            // eslint-disable-next-line no-use-before-define
            modifyInteraction.setActive(true)
            // eslint-disable-next-line no-use-before-define
            selectInteraction.setActive(true)
            drawingSource.addFeatures([...vertexFeatures, ...edgeFeatures])

            this.plotDrawing.polygonFeature
              .getGeometry()
              .getCoordinates()[0]
              .slice(0, PLOT_POINTS_NUMBER)
              .forEach((coordinates, i) => {
                const vertexFeature = vertexFeatures[i]
                vertexFeature.getGeometry().setCoordinates(coordinates)
              })
            this.plotDrawing.polygonFeature.setStyle(getPolygonStyleFunction())
          }

          this.isPlotFeatureDrawn = !!features.length

          if (!this.isPlotFeatureDrawn) {
            vertexFeatures.forEach((vertexFeature) => {
              vertexFeature.set(PLOT_VERTEX_STATE_PROPERTY, null)
            })
          }
        })

        const drawInteraction = new Draw({
          source: drawingSource,
          type: GeometryType.POLYGON,
          stopClick: true,
          minPoints: PLOT_POINTS_NUMBER,
          maxPoints: PLOT_POINTS_NUMBER,
          condition: (event) => noModifierKeys(event) && !this.isPlotFeatureDrawn
        })
        {
          // hide the pointer if a feature is drawn already
          const defaultStyle = drawInteraction.getOverlay().getStyle()
          drawInteraction.getOverlay().setStyle((feature, resolution) => {
            if (this.isPlotFeatureDrawn) {
              return
            }
            return defaultStyle(feature, resolution)
          })
        }

        const modifyInteraction = new Modify({
          features: new Collection(vertexFeatures), // only vertices can be modified directly
          deleteCondition: never,
          insertVertexCondition: never
        })

        const selectInteraction = new Select({
          removeCondition: click,
          toggleCondition: never,
          layers: [drawingLayer],
          filter: (feature) =>
            feature.get(PLOT_VERTEX_STATE_PROPERTY) === PlotVertexState.CAN_BE_BOUND_TO_COASTLINE ||
            feature.get(PLOT_EDGE_STATE_PROPERTY) === PlotEdgeState.CAN_BE_BOUND_TO_COASTLINE,
          style: (feature) =>
            [
              feature.getGeometry().getType() === GeometryType.POINT ? vertexSelectStyle : edgeSelectStyle,
              feature.get('style')(feature)
            ].flat()
        })
        selectInteraction.on('select', ({ selected }) => {
          const feature = selected[0]
          if (feature) {
            const geometry = feature.getGeometry()
            const isVertex = geometry.getType() === GeometryType.POINT
            const position = isVertex
              ? geometry.getCoordinates()
              : geometry.getClosestPoint(getCenter(geometry.getExtent()))
            this.overlay = {
              overlay: new Overlay({
                position,
                positioning: 'bottom-center',
                offset: [0, -15],
                element: this.$refs.bindPlotToCoastlineButton.$el
              }),
              feature,
              isVertex
            }
            this.map.addOverlay(this.overlay.overlay)
          } else {
            this.hideOverlay()
          }
        })

        this.map.addInteraction(drawInteraction)
        drawInteraction.setActive(false)
        this.map.addInteraction(modifyInteraction)
        modifyInteraction.setActive(false)
        this.map.addInteraction(selectInteraction)
        selectInteraction.setActive(false)

        this.plotDrawing.source = drawingSource
        this.plotDrawing.layer = drawingLayer
        this.plotDrawing.drawInteraction = drawInteraction
        this.plotDrawing.modifyInteraction = modifyInteraction
        this.plotDrawing.selectInteraction = selectInteraction
        this.plotDrawing.vertexFeatures = vertexFeatures
        this.plotDrawing.edgeFeatures = edgeFeatures
      },

      hideOverlay() {
        if (this.overlay) {
          this.plotDrawing.selectInteraction.getFeatures().clear()
          this.map.removeOverlay(this.overlay.overlay)
          this.overlay = null
        }
      },

      bindPlotToCoastline() {
        const { feature, isVertex } = this.overlay

        const coordinates = feature.getGeometry().getCoordinates()
        let newCoordinates = isVertex
          ? bindPlotVertexToCoastline(this.coastlinesIndex, coordinates)
          : bindPlotEdgeToCoastline(this.coastlinesIndex, coordinates)

        if (!isVertex) {
          if (getDistance(newCoordinates[0], coordinates[0]) > getDistance(newCoordinates[0], coordinates[1])) {
            newCoordinates.reverse()
          }
          // coordinates of new line doesn't includes the original points for some reason
          newCoordinates = [coordinates[0], ...newCoordinates.slice(1, newCoordinates.length - 1), coordinates[1]]
        }

        feature.getGeometry().setCoordinates(newCoordinates)

        if (!isVertex) {
          feature.set(PLOT_EDGE_STATE_PROPERTY, PlotEdgeState.BOUND_TO_COASTLINE)
        }

        this.hideOverlay()
      },

      handleIsPlotDrawingActiveChange() {
        this.plotCoordinates = null
        this.hideOverlay()
        this.plotDrawing.source.clear()
        this.plotDrawing.polygonFeature = null
        this.plotDrawing.drawInteraction.setActive(this.isPlotDrawingActive)

        if (!this.isPlotDrawingActive) {
          this.plotDrawing.modifyInteraction.setActive(false)
          this.plotDrawing.selectInteraction.setActive(false)
        }
      },

      handleViewChange() {
        const olView = this.map.getView()
        const mapSize = this.map.getSize()
        this.visibleViewExtent = olView.calculateExtent(mapSize)
      },

      zoomToBasin(immediate = false) {
        let extent = basinExtents[Basin.FAR_EAST]

        const { basin } = this.$route.query
        if (basin) {
          if (Object.values(Basin).includes(basin)) {
            this.basin = basin
            extent = basinExtents[basin]
          }
          this.$router.replace({ query: null })
        }

        const params = immediate ? { duration: 0 } : undefined
        zoomToCoordinates(this.map, extent, params)
      },

      zoomToPlot(plot) {
        if (plot.id) {
          zoomToFeature(this.map, layerConfigurations.buffer_zones.get('id'), plot.id, { padding: [12, 12, 12, 12] })
        } else {
          const feature = createPolygonFeature(plot.geometry.coordinates)
          const olFeature = new GeoJSON().readFeature(feature, {
            dataProjection: EPSG_4326,
            featureProjection: EPSG_3857
          })
          const extent = olFeature.getGeometry().getExtent()
          zoomToCoordinates(this.map, extent, { padding: [32, 32, 32, 32] })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .map-view {
    position: relative;

    &::v-deep {
      .ol-overviewmap {
        left: 52px;
        bottom: 32px;
        width: 220px;
        height: 150px;
        padding: unset;
        border: unset;
        background: unset !important;

        .ol-overviewmap-map {
          width: 100%;
          border: unset;
          margin: unset;
          background: white;
        }
      }

      .ol-zoom {
        left: unset;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }

      .ol-scale-line {
        left: unset;
        right: 8px;
        bottom: 28px;
      }

      .ol-mouse-position {
        top: unset;
        bottom: 58px;
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 0.875rem;
        background: rgba(255, 255, 255, 0.8);
      }

      .ol-attribution {
        font-size: 0.75rem;
      }
    }

    .region-selector {
      position: absolute;
      z-index: 5;
      top: 24px;
      left: 52px;
    }

    .create-plot-btn,
    &::v-deep .plot-drawing-manual__btn {
      position: absolute;
      z-index: 5;
      right: 12px;
      top: 20px;
    }

    .plots-card,
    .plot-coordinates-card {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 5;
    }

    .plot-drawing-controls {
      position: absolute;
      top: 8px;
      margin-left: 50%;
      transform: translateX(-50%);
      z-index: 5;
    }

    .map-legend {
      position: absolute;
      right: 8px;
      bottom: 92px;
      z-index: 5;
    }
  }
</style>
