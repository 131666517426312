<template>
  <v-menu class="plot-drawing-manual" offset-y bottom left>
    <template #activator="{ on, attrs, value }">
      <v-btn class="plot-drawing-manual__btn" depressed v-bind="attrs" v-on="on">
        Справка

        <v-icon :class="{ 'rotate-180': value }" right>
          {{ $vuetify.icons.values.dropdown }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <!--<v-card-title>-->
      <!--  Рисование участка-->

      <!--  <v-btn class="ml-auto" small icon @click="isManualActive = false">-->
      <!--    <v-icon>{{ $vuetify.icons.values.close }}</v-icon>-->
      <!--  </v-btn>-->
      <!--</v-card-title>-->

      <v-card-text>
        <p>Вершины геометрии участка:</p>

        <ul>
          <li>
            <div class="vertex-preview" :style="{ backgroundColor: DEFAULT_PLOT_ELEMENT_COLOR }" />
            – вершина находится далеко от береговой линии (> {{ CAN_BE_BOUND_TO_COASTLINE_DISTANCE }} м)
          </li>
          <li>
            <div class="vertex-preview" :style="{ backgroundColor: CAN_BE_BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR }" />
            – вершина находится близко береговой линии (≤ {{ CAN_BE_BOUND_TO_COASTLINE_DISTANCE }} м), поэтому может
            быть притянута к ней
          </li>
          <li>
            <div class="vertex-preview" :style="{ backgroundColor: BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR }" />
            – вершина притянута к береговой линии
          </li>
        </ul>

        <p class="mt-4">Рёбра геометрии участка:</p>

        <ul>
          <li>
            <div class="edge-preview" :style="{ backgroundColor: DEFAULT_PLOT_ELEMENT_COLOR }" />
            – ребро не может быть притянуто к береговой линии
          </li>
          <li>
            <div class="edge-preview" :style="{ backgroundColor: CAN_BE_BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR }" />
            – обе вершины ребра притянуты к береговой линии, поэтому ребро тоже может быть притянуто
          </li>
          <li>
            <div class="edge-preview" :style="{ backgroundColor: BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR }" />
            – ребро притянуто к береговой линии
          </li>
        </ul>

        <p class="mt-4 mb-0">
          Чтобы притянуть вершину или ребро к береговой линии, необходимо кликнуть по соответствующему элементу, а затем
          нажать появившуюся кнопку "Притянуть к берегу"
        </p>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
  import { CAN_BE_BOUND_TO_COASTLINE_DISTANCE } from '@/utils/plots'
  import {
    BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR,
    CAN_BE_BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR,
    DEFAULT_PLOT_ELEMENT_COLOR
  } from './plotDrawing'

  export default {
    name: 'PlotDrawingManual',

    data() {
      return {
        CAN_BE_BOUND_TO_COASTLINE_DISTANCE,
        DEFAULT_PLOT_ELEMENT_COLOR,
        CAN_BE_BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR,
        BOUND_TO_COASTLINE_PLOT_ELEMENT_COLOR
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vertex-preview {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    vertical-align: middle;
  }

  .edge-preview {
    display: inline-block;
    width: 20px;
    height: 4px;
    vertical-align: middle;
  }
</style>
