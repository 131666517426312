<template>
  <div class="plots-list flex-grow-1 d-flex flex-column overflow-hidden">
    <plot-filters class="pa-4" @change="filters = $event" />

    <v-virtual-scroll
      class="flex-grow-1 overflow-y-scroll thin-scrollbar"
      :items="filteredPlots"
      :bench="2"
      :item-height="142"
    >
      <template #default="{ item }">
        <plot-card :plot="item" />
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { get } from 'vuex-pathify'
  import { intersects } from 'ol/extent'
  import { MAX_PLOT_AREA } from '@/utils/plots'
  import { PlotType } from './plotsList'
  import PlotCard from '../PlotCard'
  import PlotFilters from './PlotFilters'

  export default {
    name: 'PlotsList',
    components: { PlotFilters, PlotCard },

    data() {
      return {
        MAX_PLOT_AREA,

        filters: {}
      }
    },

    computed: {
      ...get('map', {
        visibleViewExtent: 'visibleViewExtent',
        plots: 'sortedPlots',
        favoritePlots: 'favoritePlots'
      }),

      filteredPlots() {
        if (_.isEmpty(this.filters)) {
          return this.plots
        }

        const filters = []

        if (this.filters.waterBody?.length) {
          filters.push(['water_body', (waterBody) => this.filters.waterBody.includes(waterBody)])
        }

        if (this.filters.federalSubject?.length) {
          filters.push(['federal_subject', (federalSubject) => this.filters.federalSubject.includes(federalSubject)])
        }

        if (this.filters.status?.length) {
          filters.push(['status', (status) => this.filters.status.includes(status)])
        }

        let nameFilter = this.filters.name ? this.filters.name.trim() : null
        if (nameFilter) {
          nameFilter = nameFilter.toLowerCase()
          filters.push(['name', (name) => name && name.toLowerCase().includes(nameFilter)])
        }

        filters.push(['area_ha', (area) => area >= this.filters.area[0] && area <= this.filters.area[1]])

        if (this.$auth.authenticated) {
          if (this.filters.type === PlotType.OWN) {
            filters.push(['user', (user) => user === this.$auth.user.login])
          } else if (this.filters.type === PlotType.FAVORITE) {
            filters.push([undefined, (plot) => plot.id in this.favoritePlots])
          }
        }

        if (this.filters.onlyFromVisibleView) {
          filters.push(['extent', (extent) => intersects(this.visibleViewExtent, extent)])
        }

        return this.plots.filter((plot) =>
          filters.every(([propertyName, predicate]) => predicate(propertyName ? plot[propertyName] : plot))
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .plots-list {
    .plot-card {
      height: 138px;

      &:not(:first-of-type) {
        margin-top: 4px;
      }
    }
  }
</style>
