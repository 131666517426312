<template>
  <div class="map-view d-flex flex-grow-1 fill-height">
    <map-container class="flex-grow-1" />

    <aside-panel />
  </div>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  import { PlotStatus } from '@/enums/aquarius'
  import { AsidePanelState } from '@/views/MapView/mapView'
  import MapContainer from './MapContainer/MapContainer'
  import AsidePanel from './AsidePanel/AsidePanel'

  export default {
    name: 'MapView',
    components: { AsidePanel, MapContainer },

    computed: {
      ...get('map', {
        selectedPlot: 'selectedPlot'
      })
    },

    created() {
      this.$auth.$on('logout', () => {
        if (this.selectedPlot?.status === PlotStatus.DRAFT) {
          this.setAsidePanelState([AsidePanelState.PLOTS_LIST])
        }

        this.fetchPlots()
      })

      this.fetchPlots()
    },

    destroyed() {
      this.resetState()
    },

    methods: {
      ...call('map', {
        resetState: 'resetState',
        setAsidePanelState: 'setAsidePanelState',
        fetchPlots: 'fetchPlots'
      })
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
