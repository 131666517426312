// helpers for OpenLayers
import _ from 'lodash'
import axios from 'axios'
import View from 'ol/View'
import TileWMS from 'ol/source/TileWMS'
import GeoJSON from 'ol/format/GeoJSON'
import GeometryType from 'ol/geom/GeometryType'
import TileState from 'ol/TileState'
import { createEmpty as createEmptyExtent, isEmpty as isEmptyExtent, extend as extendExtent } from 'ol/extent'
import { linear as linearEasing } from 'ol/easing'

const maxPointZoom = 15

const view = new View()
const _zoomToResolution = _.range(25).map((zoom) => view.getResolutionForZoom(zoom))
/**
 * Returns resolution according to given zoom level
 * Always valid only for default settings of ol.View
 * @param {number} zoom
 * @returns {number}
 */
export function zoomToResolution(zoom) {
  return _zoomToResolution[zoom]
}

const _WMSSource = new TileWMS({
  params: { LAYERS: '' },
  url: ''
})
/**
 * Returns the GetFeatureInfo URL for the passed coordinate, resolution, and projection
 * Just the wrapper for ol.TileWMS.getGetFeatureInfoUrl() with some default params
 * @param {string} geoServerURL
 * @param {Coordinate} coordinate
 * @param {number} resolution
 * @param {ProjectionLike} projection
 * @param {Object} [params]
 * @returns {string | undefined}
 */
export function getGetFeatureInfoURL(geoServerURL, coordinate, resolution, projection, params = {}) {
  // handle case when settings could be changed
  _WMSSource.setUrl(`${geoServerURL}/wms`) // full URL
  params = {
    INFO_FORMAT: 'application/json',
    FEATURE_COUNT: 5,
    ...params
  }

  return _WMSSource.getGetFeatureInfoUrl(coordinate, resolution, projection, params)
}

export const GeoJSONParser = new GeoJSON()

/**
 * @param {module:ol/extent~Extent[]} extents
 * @returns {module:ol/extent~Extent|undefined}
 */
export function sumExtents(extents) {
  if (!extents.length) {
    return
  }
  const result = createEmptyExtent()
  extents.forEach((extent) => extendExtent(result, extent))
  return result
}

/**
 * Returns angle in OpenLayers coordinate system (positive rotation clockwise, 0 means North)
 * @param {number} angle - in radians
 * @returns {number}
 */
export function getAngleInOLCoordinateSystem(angle) {
  return angle * -1 + Math.PI / 2
}

/**
 * @param {module:ol/layer/Base} olLayer
 * @returns {string}
 */
export function getOlLayerID(olLayer) {
  return olLayer.get('id')
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string[]} layersIDs
 * @returns {module:ol/layer/Base[]}
 */
export function getOlLayersByIDs(olMap, layersIDs) {
  const layersIDsSet = new Set(layersIDs)
  return olMap
    .getLayers()
    .getArray()
    .filter((olLayer) => layersIDsSet.has(getOlLayerID(olLayer)))
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string[]} layersIDs
 * @returns {boolean}
 */
export function olLayersExist(olMap, layersIDs) {
  return getOlLayersByIDs(olMap, layersIDs).length === layersIDs.length
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {module:ol/layer/Base|undefined}
 */
export function getOlLayerById(olMap, layerID) {
  return olMap
    .getLayers()
    .getArray()
    .map((layer) => {
      // TODO: find out this way because geoportal and project may refer
      //  to the different OpenLayers packages due dependencies conflict
      const isLayerGroup = !!layer.getLayers
      // TODO: probably should be recursive
      return isLayerGroup ? layer.getLayers().getArray() : layer
    })
    .flat()
    .find((olLayer) => getOlLayerID(olLayer) === layerID)
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {boolean}
 */
export function olLayerExists(olMap, layerID) {
  return !!getOlLayerById(olMap, layerID)
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {module:ol/source/Source~Source|undefined}
 */
export function getOlLayerSource(olMap, layerID) {
  const olLayer = getOlLayerById(olMap, layerID)
  if (!olLayer) {
    return
  }
  return olLayer.getSource()
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @param {string} featureID
 * @returns {module:ol/Feature~Feature|undefined}
 */
export function getOlFeature(olMap, layerID, featureID) {
  return getOlLayerSource(olMap, layerID)?.getFeatureById(featureID)
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @param {string} featureID
 * @returns {boolean}
 */
export function olFeatureExists(olMap, layerID, featureID) {
  return !!getOlFeature(olMap, layerID, featureID)
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @param {string} featureID
 * @returns {module:ol/geom/Geometry~Geometry|undefined}
 */
export function getOlFeatureGeometry(olMap, layerID, featureID) {
  return getOlFeature(olMap, layerID, featureID)?.getGeometry()
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @param {string} featureID
 * @returns {module:ol/extent~Extent|undefined}
 */
export function getOlFeatureExtent(olMap, layerID, featureID) {
  return getOlFeatureGeometry(olMap, layerID, featureID)?.getExtent()
}

/**
 * Updates style of vector layer features immediately if that layer is visible
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {module:ol/source/Source~Source|undefined}
 */
export function renderLayerFeatures(olMap, layerID) {
  const olLayer = getOlLayerById(olMap, layerID)
  if (!olLayer?.getVisible()) {
    return
  }

  const olLayerSource = getOlLayerSource(olMap, layerID)
  if (!olLayerSource) {
    return
  }
  olLayerSource.changed()
  return olLayerSource
}

/**
 * Causes to download features again
 * Only for layers with ol/source/Vector layer source
 * @deprecated use refreshLayer instead
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {module:ol/source/Source~Source|undefined}
 */
export function removeLayerFeatures(olMap, layerID) {
  const olLayerSource = getOlLayerSource(olMap, layerID)
  if (!olLayerSource) {
    return
  }
  olLayerSource.clear()
  return olLayerSource
}

/**
 * Clears the source and reloads data
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {module:ol/source/Source~Source|undefined}
 */
export function refreshLayer(olMap, layerID) {
  const olLayerSource = getOlLayerSource(olMap, layerID)
  if (!olLayerSource) {
    return
  }
  olLayerSource.refresh()
  return olLayerSource
}

/**
 * Causes to download tiles of WMS layer again
 * Explanation: https://gis.stackexchange.com/a/23782
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {module:ol/source/Source~Source|undefined}
 */
export function redrawWMSLayer(olMap, layerID) {
  const olLayerSource = getOlLayerSource(olMap, layerID)
  if (!olLayerSource) {
    return
  }
  olLayerSource.updateParams({ __REVISION__: Date.now() })
  return olLayerSource
}

const defaultZoomConfig = { duration: 500, easing: linearEasing }

// TODO: make it async function, return whether the zooming was successful
/**
 * @param {module:ol/Map~Map} olMap
 * @param {module:ol/extent~Extent|number[]} coordinates
 * @param {Object} [params]
 */
export function zoomToCoordinates(olMap, coordinates, params = {}) {
  const view = olMap.getView()

  const isPoint = coordinates.length <= 3 // point may have 3 coordinates (altitude)
  const extent = isPoint
    ? [...coordinates.slice(0, 2), ...coordinates.slice(0, 2)] // point
    : coordinates

  if (isPoint && !('maxZoom' in params)) {
    params = { ...params, maxZoom: maxPointZoom }
  }

  view.fit(extent, { ...defaultZoomConfig, ...params })
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {module:ol/extent~Extent|undefined}
 */
export function getLayerExtent(olMap, layerID) {
  const olLayer = getOlLayerById(olMap, layerID)
  if (!olLayer) {
    return
  }
  return olLayer.getExtent()
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {module:ol/extent~Extent|undefined}
 */
export function getLayerSourceExtent(olMap, layerID) {
  const olLayerSource = getOlLayerSource(olMap, layerID)
  if (!olLayerSource || !olLayerSource.getExtent) {
    return
  }
  return olLayerSource.getExtent()
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @returns {module:ol/extent~Extent|undefined}
 */
function getLayerExtentHelper(olMap, layerID) {
  let extent = getLayerExtent(olMap, layerID)
  if (!extent || isEmptyExtent(extent)) {
    extent = getLayerSourceExtent(olMap, layerID)
    if (!extent || isEmptyExtent(extent)) {
      return
    }
  }
  return extent
}

/**
 * @typedef {Object} DelayConfiguration
 * @property {number} [maxWait]
 * @property {number} [waitStep]
 */

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @param {Object} [zoomParams]
 */
export function zoomToLayer(olMap, layerID, zoomParams = {}) {
  const extent = getLayerExtentHelper(olMap, layerID)
  if (!extent) {
    return
  }
  zoomToCoordinates(olMap, extent, zoomParams)
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string[]} layersIDs
 * @param {Object} [zoomParams]
 */
export function zoomToLayers(olMap, layersIDs, zoomParams = {}) {
  // TODO: could be optimized
  const extents = layersIDs.map((layerID) => getLayerExtentHelper(olMap, layerID)).filter(Boolean)
  if (!extents.length) {
    return
  }
  const extent = sumExtents(extents)
  zoomToCoordinates(olMap, extent, zoomParams)
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @param {string} featureID
 * @param {Object} [zoomParams]
 */
export function zoomToFeature(olMap, layerID, featureID, zoomParams = {}) {
  const olGeometry = getOlFeatureGeometry(olMap, layerID, featureID)
  if (!olGeometry) {
    return
  }
  const extent = olGeometry.getExtent()
  if (!extent) {
    return
  }

  if (!('maxZoom' in zoomParams) && olGeometry.getType() === GeometryType.POINT) {
    zoomParams = { ...zoomParams, maxZoom: maxPointZoom }
  }

  zoomToCoordinates(olMap, extent, zoomParams)
}

/**
 * @param {module:ol/Map~Map} olMap
 * @param {string} layerID
 * @param {string[]} featuresIDs
 * @param {Object} [zoomParams]
 */
export function zoomToFeatures(olMap, layerID, featuresIDs, zoomParams = {}) {
  const featuresGeometries = featuresIDs
    .map((featureID) => getOlFeatureGeometry(olMap, layerID, featureID))
    .filter(Boolean)
  const extents = featuresGeometries.map((olGeometry) => olGeometry.getExtent()).filter(Boolean)
  if (!extents.length) {
    return
  }

  const extent = sumExtents(extents)

  if (featuresGeometries.some((geometry) => geometry.getType() === GeometryType.POINT)) {
    zoomParams = { ...zoomParams, maxZoom: maxPointZoom }
  }

  zoomToCoordinates(olMap, extent, zoomParams)
}

// passes all parameters in body of request
// could be useful when the length of URL is too big
export async function wmsTileLoadFunction(imageTile, src) {
  const [url, queryParams] = src.split('?')

  let response
  try {
    response = await axios.post(url, new URLSearchParams(queryParams), { responseType: 'blob' })
  } catch (e) {
    imageTile.setState(TileState.ERROR)
    return
  }

  imageTile.getImage().src = URL.createObjectURL(response.data)
}
