import _ from 'lodash'
import { sortByProperty } from 'geoportal/src/utils/func'
import { sumExtents } from 'geoportal/src/utils/ol'
import { Basin } from '@/enums/aquarius'

export const regionOptions = {
  [Basin.FAR_EAST]: [
    {
      title: 'Приморский край',
      regionName: 'Приморский',
      regionType: 'край',
      coordinates: [14498960.396383576, 5145076.228094911, 15521625.00559273, 6251334.804859437]
    },
    {
      title: 'Хабаровский край',
      regionName: 'Хабаровский',
      regionType: 'край',
      coordinates: [14491235.321226805, 5829398.924878889, 16435454.036247713, 9064933.578674568]
    },
    {
      title: 'Сахалинская область',
      regionName: 'Сахалинская',
      regionType: 'область',
      coordinates: [15742970.65509661, 5394461.410890907, 17530149.27210319, 7358389.49899936]
    },
    {
      title: 'Магаданская область',
      regionName: 'Магаданская',
      regionType: 'область',
      coordinates: [15980631.492717842, 8078652.964247285, 18236667.465803828, 9976743.673342623]
    },
    {
      title: 'Камчатский край',
      regionName: 'Камчатский',
      regionType: 'край',
      coordinates: [16974838.929461475, 6573714.74659797, 19496760.05412774, 9670234.665371925]
    }
  ],
  [Basin.NORTH]: [
    {
      title: 'Республика Карелия',
      regionName: 'Карелия',
      regionType: 'республика',
      coordinates: [3560865.6075970763, 9337306.712998169, 4069172.6664573224, 10191104.519069443]
    },
    {
      title: 'Мурманская область',
      regionName: 'Мурманская',
      regionType: 'область',
      coordinates: [3434328.7424123627, 9922729.897578748, 4559056.349591281, 11001448.489060584]
    },
    {
      title: 'Архангельская область',
      regionName: 'Архангельская',
      regionType: 'область',
      coordinates: [3896182.1777645745, 9100250.907059547, 6679169.447596414, 12123477.916891709]
    },
    {
      title: 'Ненецкий автономный округ',
      regionName: 'Ненецкий',
      regionType: 'автономный округ',
      coordinates: [4590073.67037598, 9982557.20494888, 7261741.449414547, 11328592.355001293]
    }
  ]
}

Object.values(regionOptions).forEach((regions) => {
  sortByProperty(regions, 'title')
})

export const basinExtents = _.mapValues(regionOptions, (regions) =>
  sumExtents(regions.map((option) => option.coordinates))
)
