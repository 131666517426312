<template>
  <v-dialog v-model="isActive" :width="500">
    <v-card>
      <v-card-title>
        Внимание!

        <v-btn class="ml-auto" small icon @click="isActive = false">
          <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <div class="px-4 text-justify">
        <p v-if="!$auth.authenticated" class="error--text">
          Для формирования границ рыбоводного участка и направления заявления в Росрыболовство необходимо
          авторизоваться. Черновик границ рыбоводного участка для неавторизованных пользователей не сохраняется.
        </p>
        <p>Границы морских объектов и акваторий заданы на основании Государственных морских навигационных карт.</p>
        <p>
          Для отображения сухопутных объектов использованы данные OpenStreetMap (OSM). OSM - является публичным
          справочно-схематическим картографическим сервисом и не может служить метрической картографической основой.
        </p>
        <p>
          В случае расхождений отображения Государственных морских навигационных карт и OpenStreetMap (OSM) отображение
          на основе Государственных морских навигационных карт является превалирующим.
        </p>
      </div>

      <v-card-actions>
        <v-btn small color="primary" @click="startPlotDrawing()">Продолжить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { call } from 'vuex-pathify'
  import { AsidePanelState } from '@/views/MapView/mapView'

  export default {
    name: 'PlotDrawingWarningDialog',

    props: {
      value: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isActive: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },

    methods: {
      ...call('map', {
        setAsidePanelState: 'setAsidePanelState'
      }),

      startPlotDrawing() {
        this.setAsidePanelState([AsidePanelState.CREATE_PLOT])
        this.isActive = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  p {
    text-indent: 1rem;
  }
</style>
