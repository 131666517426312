<template>
  <div class="region-selector">
    <div class="d-flex justify-space-between align-center">
      <v-btn small icon color="black" @click="setPreviousBasin()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <div class="font-weight-bold">
        <div class="text-uppercase">{{ $t(`aquarius.basinShortName.${basin}`) }}</div>
        <div class="text-xs">рыбохозяйственный бассейн</div>
      </div>

      <v-btn small icon color="black" @click="setNextBasin()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <v-menu :close-on-click="false" :close-on-content-click="false" left offset-y>
      <template #activator="{ on, attrs, value }">
        <v-btn
          class="w-100 mt-4 justify-space-between"
          large
          depressed
          dark
          :color="MENU_COLOR"
          v-bind="attrs"
          v-on="on"
        >
          Регионы
          <v-icon :class="{ 'rotate-180': value }" right large>
            {{ $vuetify.icons.values.dropdown }}
          </v-icon>
        </v-btn>
      </template>

      <v-list class="regions-menu__list" :elevation="0" dark :color="MENU_COLOR">
        <v-list-item
          v-for="option of basinRegionOptions"
          :key="option.title"
          @click="$bus.emit('map/zoomToCoordinates', option.coordinates)"
        >
          <div class="region-option font-weight-bold">
            <div class="region-option__name text-uppercase">{{ option.regionName }}</div>
            <div class="region-option__type">{{ option.regionType }}</div>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { Basin } from '@/enums/aquarius'
  import { basinExtents, regionOptions } from '@/configurations/regions'

  const basinsOrder = [Basin.FAR_EAST, Basin.NORTH]
  const MENU_COLOR = 'rgba(0, 0, 0, 0.6)'

  export default {
    name: 'RegionSelector',

    data() {
      return {
        basinsOrder,
        MENU_COLOR
      }
    },

    computed: {
      ...sync('map', {
        basin: 'basin'
      }),

      basinRegionOptions() {
        return regionOptions[this.basin]
      }
    },

    methods: {
      selectBasin(basin) {
        this.basin = basin
        this.$bus.emit('map/zoomToCoordinates', basinExtents[basin])
      },

      setPreviousBasin() {
        let index = basinsOrder.indexOf(this.basin) - 1
        index = index === -1 ? basinsOrder.length - 1 : index
        this.selectBasin(basinsOrder[index])
      },

      setNextBasin() {
        let index = basinsOrder.indexOf(this.basin) + 1
        index = index === basinsOrder.length ? 0 : index
        this.selectBasin(basinsOrder[index])
      }
    }
  }
</script>

<style lang="scss" scoped>
  .region-selector {
    width: 250px;
    line-height: normal;
  }

  .regions-menu__list {
    .v-list-item {
      min-height: unset;
      padding: 20px;

      .region-option {
        .region-option__name {
          font-size: 18px;
          line-height: 21px;
        }

        .region-option__type {
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
  }
</style>
