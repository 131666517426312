<template>
  <div class="create-plot-panel flex-grow-1 d-flex flex-column overflow-y-hidden white">
    <v-sheet class="px-5 py-3">
      <div class="d-flex justify-space-between align-center">
        <div class="create-plot-panel__title font-weight-bold">Новый участок</div>

        <v-btn icon small @click.stop="cancel()">
          <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
        </v-btn>
      </div>

      <div class="d-flex justify-space-between align-center mt-4">
        <div class="create-plot-panel__area">{{ area }} га</div>

        <v-btn class="plot-card__zoom-btn" :disabled="!plotCoordinates" small icon @click.stop="zoomToPlot()">
          <v-icon>mdi-image-filter-center-focus</v-icon>
        </v-btn>
      </div>
    </v-sheet>

    <div class="flex-grow-1 mt-9 px-5 py-3 overflow-y-auto thin-scrollbar">
      <div class="font-weight-bold">Координаты углов участка</div>

      <plot-coordinates
        :value="coordinates"
        class="mt-3"
        :disabled="!plotCoordinates"
        @input="$bus.emit('map/updatePlotCoordinates', $event)"
      />
    </div>

    <v-sheet class="create-plot-panel__actions d-flex pa-3">
      <v-btn
        class="flex-grow-1"
        :disabled="!plotCoordinates || isPending"
        :loading="isPending"
        small
        depressed
        color="primary"
        @click="proceed()"
      >
        Далее
      </v-btn>
    </v-sheet>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { get, call } from 'vuex-pathify'
  import { polygon as createPolygonFeature } from '@turf/helpers'
  import GeoJSON from 'ol/format/GeoJSON'
  import { toLonLat } from 'geoportal/src/utils/geo'
  import { beforeDestroy } from 'geoportal/src/utils/vue'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { EMPTY_VALUE_PLACEHOLDER } from '@/configurations/common'
  import { plotPropertiesPrecision } from '@/configurations/aquarius'
  import { formatNumber } from '@/utils/common'
  import { PLOT_POINTS_NUMBER } from '@/utils/plots'
  import { AsidePanelState } from '@/views/MapView/mapView'
  import { checkPlotGeometry } from '@/views/MapView/MapContainer/plotDrawing'
  import PlotCoordinates from '@/views/MapView/AsidePanel/PlotCoordinates'

  export default {
    name: 'CreatePlotPanel',
    components: { PlotCoordinates },
    mixins: [PendingTasksManagerMixin],

    computed: {
      ...get('map', {
        plotCoordinates: 'plotCoordinates',
        plotArea: 'plotArea'
      }),

      area() {
        return _.isNil(this.plotArea)
          ? EMPTY_VALUE_PLACEHOLDER
          : formatNumber(this.plotArea, plotPropertiesPrecision.area)
      },

      coordinates() {
        return this.plotCoordinates ?? _.range(PLOT_POINTS_NUMBER).map(() => [undefined, undefined])
      }
    },

    created() {
      const keyUpHandler = (event) => {
        if (event.key === 'Escape') {
          this.cancel()
        }
      }
      document.addEventListener('keyup', keyUpHandler)
      beforeDestroy(this, () => document.removeEventListener('keyup', keyUpHandler))
    },

    methods: {
      ...call('map', {
        setAsidePanelState: 'setAsidePanelState'
      }),

      zoomToPlot() {
        this.$bus.emit('map/zoomToDrawnPlotFeature')
      },

      cancel() {
        this.setAsidePanelState([AsidePanelState.PLOTS_LIST])
      },

      async getPlotGeometry() {
        return new Promise((resolve, reject) => {
          this.$bus.emit('map/getPlotGeometry', resolve, reject)
        })
      },

      @handlePendingTask()
      async proceed() {
        let polygonCoordinates = await this.getPlotGeometry()
        const polygonFeature = new GeoJSON().readFeature(createPolygonFeature(polygonCoordinates))

        const check = checkPlotGeometry(polygonFeature)
        if (!check) {
          return
        }

        const pointsCoordinates = [[...this.plotCoordinates, this.plotCoordinates[0]].reverse()]
        polygonCoordinates = polygonCoordinates.map((ring) => ring.map(toLonLat))

        const plot = await this.$api.backend.plots.create_plot({
          plot: {
            geometry: {
              type: 'Polygon',
              coordinates: polygonCoordinates
            },
            coordinates: {
              type: 'Polygon',
              coordinates: pointsCoordinates
            }
          },
          save: false
        })

        this.setAsidePanelState([AsidePanelState.PLOT, { drawnPlot: plot }])
      }
    }
  }
</script>

<style lang="scss" scoped>
  .create-plot-panel {
    .create-plot-panel__title {
      font-size: 20px;
      line-height: 23px;
    }

    .create-plot-panel__area {
      font-size: 24px;
      line-height: 28px;
    }

    .create-plot-panel__actions {
      position: sticky;
      bottom: 0;
    }
  }
</style>
