// based on: https://stackoverflow.com/a/31074563/4729582
export class Deferred {
  constructor() {
    this._resolvePromise = null
    this._rejectPromise = null

    this._promise = new Promise((resolve, reject) => {
      this._resolvePromise = resolve
      this._rejectPromise = reject
    })
  }

  get promise() {
    return this._promise
  }

  resolve(data) {
    this._resolvePromise(data)
  }

  reject(data) {
    this._rejectPromise(data)
  }
}
