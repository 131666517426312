<template>
  <div v-if="plot" class="plot-panel flex-grow-1 d-flex flex-column overflow-hidden">
    <plot-card :plot="plot" />

    <div class="plot-panel__properties flex-grow-1 px-3 py-5 overflow-y-scroll thin-scrollbar">
      <v-sheet class="properties-grid">
        <div>
          <div>Начальная цена</div>
          <div class="property-value">{{ formattedPlot.starting_price }}</div>
        </div>

        <div>
          <div>Задаток</div>
          <div class="property-value">{{ formattedPlot.deposit }}</div>
        </div>

        <div>
          <div>Шаг аукциона</div>
          <div class="property-value">{{ formattedPlot.auction_step }}</div>
        </div>
      </v-sheet>

      <div class="mt-8 block-subtitle">Ежегодный объем изъятия аквакультуры</div>

      <v-sheet class="properties-grid mt-3">
        <div>
          <div>Пастбищная</div>
          <div class="property-value">{{ formattedPlot.pasturable_catch_t }}</div>
        </div>

        <div>
          <div>Индустриальная</div>
          <div class="property-value">{{ formattedPlot.industrial_catch_t }}</div>
        </div>
      </v-sheet>

      <div class="mt-8 block-subtitle">Период выращивания аквакультуры</div>

      <v-sheet class="properties-grid mt-3">
        <div>
          <div>Пастбищная</div>
          <div class="property-value">{{ formattedPlot.pasturable_cultivation_period_y }}</div>
        </div>

        <div>
          <div>Индустриальная</div>
          <div class="property-value">{{ formattedPlot.industrial_cultivation_period_y }}</div>
        </div>
      </v-sheet>

      <div class="mt-8 block-subtitle">Условия аренды</div>

      <v-sheet class="properties-grid mt-3">
        <div>
          <div>Водопользование</div>
          <div class="property-value">{{ formattedPlot.water_use }}</div>
        </div>

        <div>
          <div>Срок</div>
          <div class="property-value">{{ formattedPlot.contract_term_y }}</div>
        </div>
      </v-sheet>

      <v-sheet v-if="hasLegacyCoordinates" class="mt-3 pa-4">
        <component :is="formattedLegacyCoordinates" />
      </v-sheet>

      <plot-coordinates v-else class="mt-3 pa-4" :value="coordinates" disabled />
    </div>

    <v-sheet class="plot-panel__actions pa-3">
      <v-sheet
        v-if="isDraft && (!$auth.authenticated || !isEmailConfirmed || !$auth.user.has_rts_binding)"
        class="mb-4 pa-3 font-weight-bold"
        rounded
        color="error"
        dark
      >
        <template v-if="!$auth.authenticated">
          Для формирования границ рыбоводного участка и направления заявления в Росрыболовство необходимо
          <router-link class="text--white" :to="{ path: '/login', query: { redirect: $route.fullPath } }">
            авторизоваться
          </router-link>
        </template>

        <template v-else-if="!isEmailConfirmed">
          Для совершения дальнейших действий с участком необходимо
          <router-link class="text--white" :to="confirmEmailRoutePath">подтвердить почту</router-link>
        </template>

        <template v-else-if="!$auth.user.has_rts_binding">
          Для направления заявления в Росрыболовство необходимо
          <router-link class="text--white" :to="bindToRtsRoutePath">
            привязать профиль к учетной записи РТС-тендер
          </router-link>
        </template>
      </v-sheet>

      <div v-if="!isNotSavedDraft" class="d-flex">
        <v-btn
          v-if="isSavedDraft"
          :disabled="isPending"
          :loading="pendingTasks.deletePlot"
          small
          depressed
          color="secondary"
          @click="handleDeletePlot()"
        >
          Удалить
        </v-btn>

        <v-btn class="flex-grow-1" small depressed color="secondary" @click="openPlotForPrint()">
          Версия для печати
          <v-icon small right>mdi-open-in-new</v-icon>
        </v-btn>

        <v-btn
          v-if="auctionUrl"
          :href="auctionUrl"
          target="_blank"
          rel="noreferrer noopener"
          small
          depressed
          color="primary"
        >
          Аукцион
          <v-icon small right>mdi-open-in-new</v-icon>
        </v-btn>
      </div>

      <div v-if="isDraft" class="d-flex" :class="{ 'mt-2': !isNotSavedDraft }">
        <v-btn
          v-if="isNotSavedDraft"
          class="flex-grow-1"
          :disabled="!$auth.authenticated || isPending"
          :loading="pendingTasks.createPlot"
          small
          depressed
          color="secondary"
          @click="handleCreatePlot()"
        >
          Сохранить
        </v-btn>

        <v-btn
          class="flex-grow-1"
          :disabled="isPending || !$auth.user.has_rts_binding"
          :loading="pendingTasks.sendPlotToTradingPlatform"
          small
          depressed
          color="primary"
          @click="handleSendPlotToTradingPlatform()"
        >
          К подаче заявления
        </v-btn>
      </div>
    </v-sheet>
  </div>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  import { openURLInNewTab, showMessage } from 'geoportal/src/utils/common'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { PlotStatus } from '@/enums/aquarius'
  import { PLOT_POINTS_NUMBER } from '@/utils/plots'
  import PlotCard from '@/views/MapView/AsidePanel/PlotCard'
  import PlotCoordinates from '@/views/MapView/AsidePanel/PlotCoordinates'
  import { RegistrationStep } from '@/views/UserRegistrationView/userRegistration'
  import { AsidePanelState } from '@/views/MapView/mapView'
  import { formatPlotProperties } from './plotPanel'

  export default {
    name: 'PlotPanel',
    components: { PlotCoordinates, PlotCard },
    mixins: [PendingTasksManagerMixin],

    computed: {
      ...get('map', {
        drawnPlot: 'drawnPlot',
        selectedPlot: 'selectedPlot'
      }),

      plot() {
        return this.selectedPlot ?? this.drawnPlot
      },

      hasLegacyCoordinates() {
        return !!this.plot.coordinates_legacy
      },

      formattedLegacyCoordinates() {
        const coordinates = this.plot.coordinates_legacy

        if (coordinates.includes('\n')) {
          return {
            render: (h) =>
              h(
                'div',
                coordinates.split('\n').map((row) => h('div', row))
              )
          }
        } else {
          return {
            render: (h) => h('span', coordinates)
          }
        }
      },

      formattedPlot() {
        return formatPlotProperties(this.plot)
      },

      coordinates() {
        return this.plot.coordinates.coordinates[0].slice(0, PLOT_POINTS_NUMBER)
      },

      isDraft() {
        return this.plot.status === PlotStatus.DRAFT
      },

      isSavedDraft() {
        return this.isDraft && !this.drawnPlot
      },

      isNotSavedDraft() {
        return this.isDraft && !!this.drawnPlot
      },

      isEmailConfirmed() {
        return !this.$auth.user.pending_email_confirmation
      },

      confirmEmailRoutePath() {
        return `/registration/${RegistrationStep.CONFIRM_EMAIL}`
      },

      bindToRtsRoutePath() {
        return `/registration/${RegistrationStep.BIND_TO_RTS}`
      },

      auctionUrl() {
        return this.plot.auction_url
      }
    },

    methods: {
      ...call('map', {
        setAsidePanelState: 'setAsidePanelState',
        createPlot: 'createPlot',
        deletePlot: 'deletePlot',
        sendPlotToTradingPlatform: 'sendPlotToTradingPlatform'
      }),

      openPlotForPrint() {
        this.$bus.emit('printPlot', this.plot)
      },

      @handlePendingTask()
      @handlePendingTask('createPlot')
      async handleCreatePlot() {
        const plot = await this.createPlot({
          geometry: this.plot.geometry,
          coordinates: this.plot.coordinates
        })
        showMessage('Участок успешно сохранен')

        this.setAsidePanelState([AsidePanelState.PLOT, { plotId: plot.id }])
      },

      @handlePendingTask()
      @handlePendingTask('deletePlot')
      async handleDeletePlot() {
        await this.deletePlot(this.plot.id)
        showMessage('Участок успешно удален')

        this.setAsidePanelState([AsidePanelState.PLOTS_LIST])
      },

      @handlePendingTask()
      @handlePendingTask('sendPlotToTradingPlatform')
      async handleSendPlotToTradingPlatform() {
        if (this.isNotSavedDraft) {
          const plot = await this.createPlot({
            geometry: this.plot.geometry,
            coordinates: this.plot.coordinates
          })

          await this.setAsidePanelState([AsidePanelState.PLOT, { plotId: plot.id }])
        }

        const applicationUrl = await this.sendPlotToTradingPlatform(this.plot.id)
        showMessage('Заявка передана на торговую площадку. Ее необходимо подтвердить в течение 10 минут')

        openURLInNewTab(applicationUrl)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .plot-panel {
    .plot-panel__properties {
      box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);

      .v-sheet.properties-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 12px;
        padding: 12px;

        .property-value {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }

    .plot-panel__actions {
      position: sticky;
      bottom: 0;

      .v-btn {
        & + .v-btn {
          margin-left: 8px;
        }
      }
    }
  }
</style>
