<template>
  <v-sheet
    class="map-legend"
    :class="{ 'pa-2': maximized }"
    :color="maximized ? 'rgba(255, 255, 255, 0.9)' : 'transparent'"
    rounded
  >
    <v-btn class="w-100" depressed @click="maximized = !maximized">
      Легенда

      <v-icon :class="{ 'rotate-180': !maximized }" right>
        {{ $vuetify.icons.values.dropdown }}
      </v-icon>
    </v-btn>

    <div v-show="maximized" class="mt-2">
      <div
        v-for="(entry, i) of legendEntries"
        :key="i"
        class="legend-entry mt-1 d-flex align-center"
        :title="entry[2] || entry[0]"
      >
        <span class="legend-entry__preview" :style="{ background: entry[1] }" />

        <span class="ml-2">{{ entry[0] }}</span>
      </div>
    </div>
  </v-sheet>
</template>

<script>
  import { plotStatusGroups } from '@/configurations/aquarius'
  import { BUFFER_ZONES_COLOR, PERMITTED_ZONES_COLOR } from '@/views/MapView/mapView'

  export default {
    name: 'MapLegend',

    data() {
      return {
        maximized: false
      }
    },

    computed: {
      legendEntries() {
        return [
          ['Буферная зона', BUFFER_ZONES_COLOR],
          ...plotStatusGroups.map((group) => [
            group.text,
            group.color,
            group.statuses.map((status) => this.$t(`aquarius.plotStatus.${status}`)).join('\n')
          ]),
          // ['Рыболовные участки', FISHING_PLOTS_COLOR],
          ['Разрешенные зоны', PERMITTED_ZONES_COLOR]
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .map-legend {
    .legend-entry {
      .legend-entry__preview {
        display: inline-block;
        width: 15px;
        height: 15px;
      }
    }
  }
</style>
