<template>
  <div class="plot-filters">
    <v-form>
      <v-select
        v-model="formModel.waterBody"
        label="Акватория"
        :items="waterBodyOptions"
        multiple
        hide-details
        dense
        filled
        background-color="white"
      >
        <template #selection="{ index }">
          <template v-if="index === 0">
            {{ `выбрано ${formModel.waterBody.length} из ${waterBodyOptions.length}` }}
          </template>
        </template>
      </v-select>

      <v-select
        v-model="formModel.federalSubject"
        label="Регион"
        :items="federalSubjectOptions"
        multiple
        hide-details
        dense
        filled
        background-color="white"
      >
        <template #selection="{ index }">
          <template v-if="index === 0">
            {{ `выбрано ${formModel.federalSubject.length} из ${federalSubjectOptions.length}` }}
          </template>
        </template>
      </v-select>

      <v-select
        v-model="formModel.status"
        label="Статус"
        :items="statusOptions"
        :menu-props="{ maxHeight: Infinity }"
        multiple
        hide-details
        dense
        filled
        background-color="white"
      >
        <template #selection="{ index }">
          <template v-if="index === 0">
            {{ `выбрано ${formModel.status.length} из ${statusOptions.length}` }}
          </template>
        </template>

        <template #item="{ item, attrs, on }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-action>
              <v-checkbox :input-value="attrs.inputValue" />
            </v-list-item-action>

            <v-list-item-content class="flex-column align-start">
              <v-list-item-title class="align-self-start">
                {{ item.text }}
              </v-list-item-title>

              <v-list-item-subtitle v-if="item.group">{{ item.group }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>

      <v-text-field v-model="formModel.name" label="Идентификатор" hide-details dense filled background-color="white" />

      <div>
        <label class="mt-2 text--secondary" for="area">Размер участка, га</label>
        <v-range-slider
          id="area"
          v-model="formModel.area"
          class="mt-1 mb-n1"
          :min="areaRestrictions.min"
          :max="areaRestrictions.max"
          hide-details
          thumb-label
          dense
        />
      </div>

      <div class="d-flex">
        <number-input
          v-model="formModel.area[0]"
          class="w-50"
          label="От"
          :min="areaRestrictions.min"
          :max="formModel.area[1]"
          hide-details
          dense
          filled
          background-color="white"
        />
        <number-input
          v-model="formModel.area[1]"
          class="ml-1 w-50"
          label="До"
          :min="formModel.area[0]"
          :max="areaRestrictions.max"
          hide-details
          dense
          filled
          background-color="white"
        />
      </div>
    </v-form>

    <v-btn class="w-100 mt-6" color="secondary" small depressed @click="resetFilters()">Сбросить фильтры</v-btn>

    <v-btn-toggle v-model="formModel.type" class="w-100 mt-5" mandatory active-class="primary" borderless dark>
      <v-btn :value="PlotType.ALL" class="flex-grow-1" small>Все</v-btn>
      <v-btn :value="PlotType.OWN" class="flex-grow-1" :disabled="!$auth.authenticated" small>Мои</v-btn>
      <v-btn :value="PlotType.FAVORITE" class="flex-grow-1" :disabled="!$auth.authenticated" small>Избранные</v-btn>
    </v-btn-toggle>

    <div class="d-flex justify-space-between align-center mt-4">
      <label for="onlyFromVisibleView">Участки из видимой зоны карты</label>
      <v-switch id="onlyFromVisibleView" v-model="formModel.onlyFromVisibleView" class="mt-0" hide-details />
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { get } from 'vuex-pathify'
  import { plotStatusGroups } from '@/configurations/aquarius'
  import { MAX_PLOT_AREA } from '@/utils/plots'
  import { PlotType } from './plotsList'

  const areaRestrictions = {
    min: 1,
    max: MAX_PLOT_AREA
  }

  function getFiltersDefaultState() {
    return {
      waterBody: [],
      federalSubject: [],
      status: [],
      name: null,
      area: [areaRestrictions.min, areaRestrictions.max],
      type: null,
      onlyFromVisibleView: false
    }
  }

  export default {
    name: 'PlotFilters',

    data() {
      return {
        PlotType,
        areaRestrictions,

        formModel: getFiltersDefaultState()
      }
    },

    computed: {
      ...get('map', {
        plots: 'plots'
      }),

      entitiesOptions() {
        const waterBodies = new Set()
        const federalSubjects = new Set()
        this.plots.forEach((plot) => {
          waterBodies.add(plot.water_body)
          federalSubjects.add(plot.federal_subject)
        })
        return _.mapValues({ waterBodies, federalSubjects }, (items) =>
          [...items]
            .filter(Boolean)
            .sort()
            .map((value) => ({ value, text: value }))
        )
      },

      waterBodyOptions() {
        return [{ value: null, text: '[Не указана в данных участка]' }, ...this.entitiesOptions.waterBodies]
      },

      federalSubjectOptions() {
        return [{ value: null, text: '[Не указан в данных участка]' }, ...this.entitiesOptions.federalSubjects]
      },

      statusOptions() {
        return plotStatusGroups
          .map((group) =>
            group.statuses.map((status) => ({
              value: status,
              text: this.$t(`aquarius.plotStatus.${status}`),
              group: group.statuses.length === 1 ? null : group.text
            }))
          )
          .flat()
      }
    },

    watch: {
      formModel: {
        handler() {
          this.$emit('change', this.formModel)
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      resetFilters() {
        this.formModel = getFiltersDefaultState()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .plot-filters {
    .v-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }

    .v-btn-toggle {
      .v-btn {
        &:not(.v-btn--active) {
          background: map-get($theme-colors, 'secondary');
        }

        &:not(:first-child) {
          border-left-width: 2px;
        }
      }
    }
  }
</style>
