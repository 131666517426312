<template>
  <v-sheet class="aside-panel d-flex flex-column overflow-hidden" tag="aside" color="transparent">
    <plots-list :class="{ 'aside-panel__inactive-panel': asidePanelState !== AsidePanelState.PLOTS_LIST }" />
    <plot-panel v-if="asidePanelState === AsidePanelState.PLOT" />
    <create-plot-panel v-else-if="asidePanelState === AsidePanelState.CREATE_PLOT" />
  </v-sheet>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { AsidePanelState } from '@/views/MapView/mapView'
  import PlotsList from './PlotsList/PlotsList'
  import PlotPanel from './PlotPanel/PlotPanel'
  import CreatePlotPanel from './CreatePlotPanel'

  export default {
    name: 'AsidePanel',
    components: { PlotsList, PlotPanel, CreatePlotPanel },

    data() {
      return {
        AsidePanelState
      }
    },

    computed: {
      ...get('map', {
        asidePanelState: 'asidePanelState'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .aside-panel {
    width: 420px;
    height: calc(100vh - 48px); // in order not to render items of virtual scroll when the global scroll becomes visible
    box-shadow: -2px 6px 9px rgba(0, 0, 0, 0.25);

    .aside-panel__inactive-panel {
      display: none !important;
    }
  }
</style>
